<template>
  <v-menu
    ref="rMenuDate"
    v-model="menuDate"
    :close-on-content-click="false"
    :nudge-right="40"
    max-width="290px"
    min-width="290px"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on }">
      <ValidationProvider
        :ref="refName"
        :name="$t(label)"
        :rules="required ? 'required' : ''"
        :vid="vid"
        slim
      >
        <template #default="{ errors, valid }">
          <v-text-field
            :disabled="disabled"
            :error-messages="errors"
            :label="hideLabel ? undefined : $t(label)"
            :prepend-inner-icon="hideIcon ? undefined : 'mdi-calendar'"
            :success="valid"
            :value="dateFormat"
            hide-details="auto"
            readonly
            v-bind="obAttrs"
            v-on="on"
            @click:clear="clearDate"
          >
            <!-- Pass on all named slots -->
            <template v-for="(_, name) in $slots" v-slot:[name]>
              <slot :name="name" />
            </template>

            <!-- Pass on all scoped slots -->
            <template
              v-for="(_, name) in $scopedSlots"
              v-slot:[name]="slotProps"
            >
              <slot :name="name" v-bind="slotProps" />
            </template>
          </v-text-field>
        </template>
      </ValidationProvider>
    </template>

    <v-date-picker
      v-model="date"
      :disabled="disabled"
      :max="max"
      :min="min"
      full-width
      v-bind="dateOptions"
      @input="setDate"
    ></v-date-picker>
  </v-menu>
</template>
<script lang="ts">
import { defaults } from "lodash";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class FormFieldDatePicker extends Vue {
  menuDate = false;
  @VModel({ type: [String, Array] }) date!: string | string[];
  @Prop({ type: String, default: "date" }) readonly label!: string;
  @Prop({ type: String, default: "date" }) readonly vid!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Boolean) readonly hideIcon!: boolean;
  @Prop(String) readonly min!: string;
  @Prop(String) readonly max!: string;
  @Prop({ type: Object, default: () => ({}) }) readonly dateOptions!: Record<
    string,
    any
  >;

  get refName() {
    return this.$_.camelCase(`field-${this.vid}`);
  }

  get dateFormat() {
    if (!this.date) {
      return undefined;
    }

    const arDates = this.$_.isArray(this.date) ? this.date : [this.date];

    return this.$_.chain(arDates)
      .map((sDate) => this.$dayjs(sDate).format("DD/MM/YYYY"))
      .join(", ")
      .value();
  }

  setDate(sValue: string | string[]) {
    // this.$emit("input", sValue);
    this.$emit("change", sValue);

    // If date options has range property, close menu only if range is complete
    if (
      this.dateOptions &&
      this.$_.isArray(sValue) &&
      this.$_.get(this.dateOptions, "range") === true &&
      sValue.length <= 1
    ) {
      return;
    }

    this.menuDate = false;
  }

  clearDate() {
    this.$emit("input", "");
    this.setDate("");
  }
  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }
}
</script>
