<template>
  <v-row class="m-auto">
    <v-col v-if="!showFields" :class="getDinamicClass" cols="12">
      <v-sheet class="pa-2" color="blue-grey lighten-3" dark outlined rounded>
        <v-chip v-if="sDateFrom" class="mr-4" color="blue-grey" label small>
          <v-icon class="mr-2" small v-text="'mdi mdi-calendar'" />
          {{ sDateFrom }}
        </v-chip>

        <v-chip
          v-if="sDateTo && sDateTo !== sDateFrom"
          color="blue-grey"
          label
          small
        >
          <v-icon class="mr-2" small v-text="'mdi mdi-calendar'" />
          {{ sDateTo }}
        </v-chip>
      </v-sheet>
    </v-col>
    <slide-y-up-transition>
      <v-col v-show="showFields" cols="12">
        <v-row>
          <v-col v-if="!hideFrom" cols="6">
            <form-field-date-picker
              v-model="localDateFrom"
              :max="dateFromMax"
              :hideIcon="compactCustomRange"
              :dense="compactCustomRange"
              :outlined="!compactCustomRange"
              label="date.from"
              required
            />
          </v-col>
          <v-col v-if="!hideTo" cols="6">
            <form-field-date-picker
              v-model="localDateTo"
              :max="dateToMax"
              :min="dateToMin"
              :hideIcon="compactCustomRange"
              :dense="compactCustomRange"
              :outlined="!compactCustomRange"
              label="date.to"
              required
            />
          </v-col>
        </v-row>
      </v-col>
    </slide-y-up-transition>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import SlideYUpTransition from "vue2-transitions/src/Slide/SlideYUpTransition.vue";

@Component({
  components: { SlideYUpTransition, FormFieldDatePicker },
})
export default class DateRangeFields extends Vue {
  @Prop(Boolean) readonly showFields!: boolean;
  @Prop(String) readonly sDateFrom!: string;
  @Prop(String) readonly sDateTo!: string;
  @Prop(Boolean) readonly hideFrom!: boolean;
  @Prop(String) readonly dateFromMax!: string;
  @Prop(Boolean) readonly hideTo!: boolean;
  @Prop(String) readonly dateToMax!: string;
  @Prop(String) readonly dateToMin!: string;
  @Prop(Boolean) readonly compactCustomRange!: boolean;
  @Prop(String) dateFrom!: string;
  @Prop(String) dateTo!: string;

  get localDateFrom() {
    return this.dateFrom;
  }

  set localDateFrom(sValue) {
    this.$emit("updateDateFrom", sValue);
  }

  get localDateTo() {
    return this.dateTo;
  }

  set localDateTo(sValue) {
    this.$emit("updateDateTo", sValue);
  }

  get getDinamicClass() {
    return this.compactCustomRange
      ? {
          "mt-0": true,
          "p-0": true,
        }
      : {
          "mt-0": true,
          "py-0": true,
          "px-8": true,
        };
  }
}
</script>
